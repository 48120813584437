<div class="custom-tooltip" *ngIf="show">
  <h3>Theme Color Customization:</h3>
  <ol>
    <li>Select your desired color.</li>
    <li>The color is instantly copied to your clipboard.</li>
    <li>Navigate to the color section.</li>
    <li>Paste your color code.</li>
  </ol>
  <div class="tips-section">
    <p><strong>Quick Tips:</strong></p>
    <ul>
      <li>You can also reference colors using their English names.</li>
      <li>You can click the eye icon to toggle between the changes and the original theme.</li>
    </ul>
  </div>
</div>