<mat-toolbar class="toolbar">
  <span>
    <a
      href="/playground"
      target="_blank"
      style="color: inherit"
      matTooltip="Open Playground"
      class="material-symbols-outlined playground"
    >
      <span> robot_2 </span>
    </a>

    <mat-icon
      class="upload"
      matTooltip="Update Common Functions"
      (click)="save_functions()"
      >update</mat-icon
    >
  </span>
  <span class="fill-toolbar"></span>

  <span>
    <mat-icon
      class="theme-switch"
      matTooltip="Switch Theme"
      (click)="toggleTheme()"
      >brightness_6</mat-icon
    >
  </span>

  <span class="fill-toolbar"></span>
  <span>
    <span>
      <a
        [href]="'https://log-book-ng.web.app/p/' + (fileName | slice : 0 : -5)"
        *ngIf="fileName"
        target="_blank"
        class="material-symbols-outlined profile"
        matTooltip="Visit Profile"
      >
        <span>account_circle</span>
      </a>
      <mat-icon class="upload" matTooltip="Update Profile" (click)="upload()"
        >update</mat-icon
      >
      <mat-icon
        class="download"
        matTooltip="Download Profile data"
        (click)="download()"
        >download</mat-icon
      >
      <mat-icon class="logout" matTooltip="Logout" (click)="logout()"
        >logout</mat-icon
      >
    </span>
  </span>
</mat-toolbar>

<div class="background">
  <jsonforms
    [data]="data"
    (dataChange)="onDataChange($event)"
    [schema]="schema"
    [uischema]="uischema"
    [renderers]="renderers"
    *ngIf="data && data.functions"
  >
  </jsonforms>
</div>
