<ng-container *ngIf="agents">
  <mat-drawer-container class="flowchart-container" autosize>
    <mat-toolbar class="toolbar">
      <span>
        <mat-icon
          matTooltip="Automation Agents team"
          class="agents-toggle"
          (click)="drawer.toggle()"
          >group_work</mat-icon
        >
      </span>
      <span class="fill-toolbar"></span>
      <span>
        <mat-icon matTooltip="Console" class="admin" [routerLink]="['/admin']"
          >dashboard</mat-icon
        >
        <mat-icon
          class="separator"
          matTooltip="Toggle"
          (click)="groupdrawer.toggle(); drawer.toggle()"
          >more_vert</mat-icon
        >
        <mat-icon
          matTooltip="Start Connection"
          class="link-off-toggle"
          (click)="connectToWebSocket()"
          *ngIf="!isWSConnected"
          >link_off</mat-icon
        >
        <mat-icon
          matTooltip="Start Connection"
          class="link-toggle"
          (click)="closeWebSocket()"
          *ngIf="isWSConnected"
          >link</mat-icon
        >
      </span>
      <span class="fill-toolbar"></span>
      <span>
        <mat-icon
          matTooltip="Automation Agents team"
          class="group-toggle"
          (click)="groupdrawer.toggle(); drawer.toggle()"
          *ngIf="sidenav.agents.length > 0"
          >group_work</mat-icon
        >
      </span>
    </mat-toolbar>

    <mat-drawer #drawer class="left-sidenav" mode="side" opened="true">
      <app-sidenav
        #sidenav
        [isWSConnected]="isWSConnected"
        [profile]="data"
      ></app-sidenav>
    </mat-drawer>
    <div class="flowchart-sidenav-content" *ngIf="data.resume">
      <app-flowchart
        #flowchart
        [messages]="groupsidenav.messages"
        [agents]="sidenav.agents"
        [profile]="data"
      ></app-flowchart>
    </div>

    <mat-drawer
      #groupdrawer
      class="right-sidenav"
      mode="side"
      position="end"
      opened="true"
    >
      <app-group-sidenav
        #groupsidenav
        [isWSConnected]="isWSConnected"
        [agents]="sidenav.agents"
      ></app-group-sidenav>
      <div
        #resizeHandle
        class="resize-handle"
        (mousedown)="startResizing(resizeHandle, $event)"
      ></div>
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
