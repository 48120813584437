<!-- Side navigation drawer -->
<div class="box">
  <p>My Groups</p>
  <mat-form-field
    class="select-profile"
    matTooltip="Select or create a new profile"
    style="width: 100%"
  >
    <mat-label>Group</mat-label>
    <mat-select>
      <mat-option *ngFor="let group of userGroups" [value]="group.value">
        <a
          style="
            width: 100% !important;
            height: 100% !important;
            display: block !important;
          "
          (click)="selectGroup(group.userBots)"
          >{{ group.group_name }}</a
        >
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<hr />
<div class="box">
  <p>My Agents</p>
  <mat-form-field
    class="select-profile"
    matTooltip="Select or create a new profile"
    style="width: 100%"
  >
    <mat-label>Agent</mat-label>
    <mat-select>
      <mat-option *ngFor="let agent of userAgents" [value]="agent.value">
        <a
          style="
            width: 100% !important;
            height: 100% !important;
            display: block !important;
          "
          (click)="selectAgent(agent)"
          >{{ agent.agent_name }}</a
        >
      </mat-option>
    </mat-select>
  </mat-form-field>

    <p style="width: 60%">New</p>
  <!-- Input for the agent name -->
  <mat-form-field class="full-width">
    <mat-label>Name</mat-label>
    <input matInput [(ngModel)]="selectedAgent.agent_name" />
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>{{ selectedAgent.type }}</mat-label>
    <mat-select>
      <mat-option *ngFor="let type of agentTypes" [value]="type">
        {{ type }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Config</mat-label>
    <textarea
      style="height: 6vh"
      matInput
      [ngModel]="configAsString"
      (ngModelChange)="onAgentConfigValueChange($event)"
    ></textarea>
  </mat-form-field>
  <!-- Textarea for system message -->
  <mat-form-field class="full-width">
    <mat-label>System message</mat-label>
    <textarea matInput [(ngModel)]="selectedAgent.message"></textarea>
  </mat-form-field>
  <mat-icon
    matTooltip="Automation Agents team"
    class="add-agent"
    (click)="addAgent(selectedAgent)"
    *ngIf="isWSConnected"
    >add_circle</mat-icon
  >
</div>
<hr />

<!-- Message display area -->
<div class="box">
  <div style="width: 100%; display: flex">
    <p style="width: 60%">Group</p>
  </div>
  <!-- Textarea for system message -->
  <mat-form-field class="full-width">
    <mat-label>Agents</mat-label>
    <textarea
      matInput
      [ngModel]="agentsAsString"
      (ngModelChange)="onAgentsValueChange($event)"
      style="height: 24vh"
    ></textarea>
  </mat-form-field>

  <!--
      <hr />
      <div class="box">
    <jsonforms
      [data]="agents"
      (dataChange)="onAgentsChange($event)"
      [schema]="schema"
      [uischema]="uischema"
      [renderers]="renderers"
      *ngIf="agents && agents.length > 0"
    >
    </jsonforms>
  </div> -->
</div>
