<ng-container *ngIf="agents">
  <mat-drawer-container class="flowchart-container" autosize>
    <mat-toolbar class="toolbar">
      <span class="left-sidenav-toggle">
        <mat-icon
          class="icon-left-sidenav"
          matTooltip="Toggle"
          (click)="agentsdrawer.toggle()"
          >more_vert</mat-icon
        >
      </span>
      <span class="fill-toolbar"></span>
      <span>
        <a
          href="/admin"
          target="_blank"
          style="color: inherit"
          matTooltip="Open Playground"
          class="material-symbols-outlined admin"
        >
          <span> dashboard </span>
        </a>
        <mat-icon class="separator inactive">more_vert</mat-icon>

        <mat-icon
          class="theme-switch"
          matTooltip="Switch Theme"
          (click)="toggleTheme()"
          >brightness_6</mat-icon
        >
        <mat-icon class="separator inactive">more_vert</mat-icon>

        <mat-icon
          matTooltip="Start Connection"
          class="link-off-toggle"
          (click)="connectToWebSocket()"
          *ngIf="!isWSConnected"
          >link_off</mat-icon
        >
        <mat-icon
          matTooltip="Start Connection"
          class="link-toggle"
          (click)="closeWebSocket()"
          *ngIf="isWSConnected"
          >link</mat-icon
        >
        <!-- <mat-icon
          matTooltip="Toggle Drawers"
          class="group-toggle"
          (click)="agentsdrawer.toggle(); groupdrawer.toggle()"
          >group_work</mat-icon
        > -->
      </span>
      <span class="fill-toolbar"></span>
      <span class="right-sidenav-toggle">
        <mat-icon
          class="icon-right-sidenav"
          matTooltip="Toggle"
          (click)="groupdrawer.toggle(); agentsdrawer.toggle()"
          >more_vert</mat-icon
        >
      </span>
    </mat-toolbar>

    <mat-drawer #agentsdrawer class="left-sidenav" mode="side" opened="true">
      <app-playground-sidenav
        #sidenav
        [isWSConnected]="isWSConnected"
        [profile]="data"
      ></app-playground-sidenav>
      <div
        #leftresizeHandle
        class="left-resize-handle"
        (mousedown)="startResizing(leftresizeHandle, $event, 'left')"
      ></div>
    </mat-drawer>
    <div class="flowchart-sidenav-content" *ngIf="data.resume">
      <app-playground-flowchart
        #flowchart
        [messages]="groupsidenav.messages"
        [agents]="sidenav.agents"
        [profile]="data"
      ></app-playground-flowchart>
    </div>

    <mat-drawer
      #groupdrawer
      class="right-sidenav"
      mode="side"
      position="end"
      opened="true"
    >
      <app-playground-group-sidenav
        #groupsidenav
        [isWSConnected]="isWSConnected"
        [agents]="sidenav.agents"
      ></app-playground-group-sidenav>
      <div
        #rightresizeHandle
        class="resize-handle"
        (mousedown)="startResizing(rightresizeHandle, $event, 'right')"
      ></div>
    </mat-drawer>
  </mat-drawer-container>
</ng-container>
